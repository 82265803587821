<template>
  <div>
    <main-component
      :currentCrop.sync="currentCrop"
      :data="mainSection"
      :period="true"
      :initDatesSynced.sync="initDates"
      :formData.sync="formData"
      @videomodal="modals.video.showed = true"
      @order="onOrder"
    >
      <component :is="buttonappend" v-bind="buttonappendAttrs"></component>
    </main-component>
    <!-- modals section -->
    <modal-component
      :showed.sync="modals.video.showed"
      :modtitle="modals.video.title"
      id="videoModal"
    >
      <video-component :video="droughtVideo" />
    </modal-component>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import partnerCodeMixin from '@/mixins/partner-code';
import changeLang from '@/mixins/changeLang';
import { postOrderProduct } from '@/api/order';
import LogService from '@/services/LogService';
import wpSection from '@/enums/wpSection';
import { sections, drought, buy } from '@/enums/stringIndices';

export default {
  mixins: [common, partnerCodeMixin, changeLang],
  components: {
    mainComponent: () => import(/* webpackChunkName: "mainComponent" */ '@/components/sections/main/mainIndex.vue'),
    modalComponent: () => import(/* webpackChunkName: "modalComponent" */ '@/components/modalComponent.vue'),
    videoComponent: () => import(/* webpackChunkName: "videoComponent" */ '@/components/embedVideo.vue'),
  },
  data() {
    return {
      modals: {
        video: {
          showed: false,
          title: '',
        },
      },
      formData: {},
      metas: {},
      buttonappend: '', /** placed after insure button component name */
      buttonappendAttrs: {}, /** attributes for buttonappend component */
      searchBotIndex: true, /** allow search index for robots */
      currentCrop: '',
      initDates: [],
      str: {
        sections,
        drought,
        buy,
      },
    };
  },
  computed: {
    ...mapState(['menu', 'langs', 'lang', 'logo', 'wpBaseUrl', 'strings']),
    ...mapState({
      crops: (state) => state.settings.crops,
    }),
    mainSection() {
      // set data for mainSection
      return {
        title: this.str.drought.crops_title,
        video: {
          video_icon: this.str.sections.video_icon,
          video_text_drought: this.str.sections.video_text_drought,
        },
        form: {
          product_title: this.str.drought.crops_select_title,
          date_from_to: this.str.drought.date_from_to,
          date_from: this.str.drought.date_from,
          date_to: this.str.drought.date_to,
        },
        button: this.str.drought.insure_btn,
        buttonDataAttr: {
          name: 'data-crop',
          value: this.formData.crop,
        },
      };
    },
    droughtVideo() {
      return {
        youtube: this.str.sections?.video_youtube_id_drought,
      };
    },
  },
  created() {
    this.init();
  },
  watch: {
    crops: {
      immediate: true,
      handler(crops) {
        const cropIds = crops.map((c) => c.id);
        if (cropIds.length > 0 && !cropIds.includes(this.currentCrop)) {
          [this.currentCrop] = cropIds;
        }
      },
    },
  },
  mounted() {
    this.loadedPage = true;

    this.$root.$on('resize', this.resize);
    this.$root.$on('scroll', this.scroll);
  },
  destroyed() {
    // unwatch
    this.$root.$off('resize', this.resize);
    this.$root.$off('scroll', this.scroll);
  },
  updated() {
  },
  methods: {
    init() {
      const {
        crop,
        start_date,
        end_date,
      } = this.$route.query;

      if (crop) this.currentCrop = crop;

      const startDate = this.$moment(start_date, 'YYYY-MM-DD', true);
      const endDate = this.$moment(end_date, 'YYYY-MM-DD', true);
      const startValid = startDate.isValid();
      const endValid = endDate.isValid();

      if (startValid && endValid) {
        this.$set(this.formData, 'startDate', startDate.toDate());
        this.$set(this.formData, 'endDate', endDate.toDate());
      }
      // request data from api
      Promise.all([
        this.getWordPressStrings(
          [
            wpSection.DROUGHT,
            wpSection.SECTIONS,
            wpSection.BUY,
          ],
        ),
        this.$store.dispatch('settings/getProductSettings', 'drought'),
      ])
        .then((res) => {
          LogService.log('await finished', res);
          LogService.log('this.str.sections', this.str.sections);
          LogService.log('this.str.drought', this.str.drought);
          this.setCrops();
        });
    },
    resize() {
      // call on event window.resize
    },
    scroll() {
      // call on event window.scroll
    },
    async order() {
      /** send order to server */

      // convert data
      const data = {
        business: {
          type: 'crop',
          subtype: this.formData.crop,
        },
        dates: this.formData.dates.map((date) => this.$moment(date).format('YYYY-MM-DD')),
        partner_code: this.partnerCode,
      };

      /**
       * add parameter  keep_order
       */
      if (`${this.$route.query.keep_order}` === '1') {
        data.keep_order = true;
      }

      // send
      return postOrderProduct(data);
    },
    setCrops() {
      let { crops } = this.$store.state.settings;
      const wpCrops = this.str.drought?.crops || [];
      crops = crops.map((crop) => ({
        ...crop,
        title: wpCrops
          .find((item) => (item.id || '').toLowerCase() === (crop.id || '').toLowerCase())
          ?.title,
        photo_url: wpCrops
          .find((item) => (item.id || '').toLowerCase() === (crop.id || '').toLowerCase())
          ?.photo.url,
        photo_srcset: wpCrops
          .find((item) => (item.id || '').toLowerCase() === (crop.id || '').toLowerCase())
          ?.photo.sizes,
      })) || [];
      LogService.log('Drought, setCrops', crops);
      this.$store.commit('settings/SET_CROPS', crops);
    },
    async onOrder() {
    //  await this.$login();
    //  await this.order()
    //      .then(() => {
    //      let name = 'location';
    //      if (!this.$route.query?.redirect) {
    //        //
    //      } else name = this.$route.query?.redirect;
    //
    //      this.$router.push({ name, params: { ...this.$route.params } });
    //    });
      const modal = {
        id: 'wet-buy-product-not-supported',
        content: `<div class="text-center">${this.str.buy.error_product_not_active}</div>`,
        actions: [
          {
            name: 'wet-buy-product-not-supported',
            title: this.strings.commonApp?.ok,
            event: 'close',
          },
        ],
        close: this.onCloseModal,
      };
      this.$store.dispatch('addModal', modal);
    },
  },
};
</script>
